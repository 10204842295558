export function formatDateTime(dateTime: Date) {
  const config = useRuntimeConfig()
  return new Intl.DateTimeFormat(config.public.site.localeCode, {
    // 3. april 2021 kl. 12:00
    dateStyle: 'long',
    timeStyle: 'short',
  }).format(dateTime)
}

export function formatTime(dateTime: Date) {
  const config = useRuntimeConfig()
  return new Intl.DateTimeFormat(config.public.site.localeCode, {
    // 12:00
    timeStyle: 'short',
  }).format(dateTime)
}

export function formatSecondsToMinuteSeconds(_seconds: number) {
  const minutes = Math.floor(_seconds / 60)
  const seconds = Math.floor(_seconds % 60)
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
  return `${minutes}:${returnedSeconds}`
}

export function formatDate(dateTime: Date, includeYear = true) {
  const config = useRuntimeConfig()

  if (includeYear) {
    return new Intl.DateTimeFormat(config.public.site.localeCode, {
      // 3. april 2021
      dateStyle: 'long',
    }).format(dateTime)
  } else {
    return new Intl.DateTimeFormat(config.public.site.localeCode, {
      // 3. april
      day: 'numeric',
      month: 'long',
    }).format(dateTime)
  }
}
